.history__container {
  background-color: #fff;
  width: 90vw;
  margin: 20px auto;
  max-width: 1000px;
}

.history__title {
  font-size: 2rem;
  text-align: center;
  color: #007f28;
  padding: 1.5rem;
  font-weight: bold;
}

.history__container img {
  height: auto;
  padding: 0px;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  padding-bottom: 1.5rem;
  max-width: 800px;
  display: block;
  margin: auto;
}
