.footer-container {
  background-color: #00aa36;
  padding: 3rem 0 3rem 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 100%;
}

.footer-links {
  width: 100%;
  max-width: 1000px;
  display: flex;
  justify-content: center;
}

.footer-link-wrapper {
  display: flex;
}

.footer-link-items {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 16px;
  margin-left: 40px;
  margin-right: 40px;
  text-align: left;
  width: 180px;
  box-sizing: border-box;
}

.footer-link-items h2 {
  margin-bottom: 16px;
}

.footer-link-items > h2 {
  color: #fff;
}

.footer-link-items a {
  color: #fff;
  text-decoration: none;
  margin-bottom: 8px;
}

.footer-link-items a:hover {
  color: #e9e9e9;
  transform: 0.3 ease-out;
}

.footer-link-items p {
  color: #fff;
  text-decoration: none;
  margin-bottom: 8px;
}

.social-media {
  max-width: 500px;
  width: 100%;
}

.social-media-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 90%;
  max-width: 230px;
  margin: 20px auto 0 auto;
}

.website-rights {
  color: #fff;
  margin-bottom: 5px;
}

@media screen and (max-width: 820px) {
  .footer-links {
    flex-direction: column;
    align-items: center;
  }

  .social-media-wrap {
    flex-direction: column;
  }
}
