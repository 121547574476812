.header-container {
  background: url("../images/deWelp.jpg") center center/cover no-repeat;
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
  object-fit: contain;
}

.header-container > h1 {
  color: #fff;
  font-size: 100px;
  margin-top: -100px;
}

.header-container > p {
  margin-top: 8px;
  color: #fff;
  font-size: 32px;
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
}

.header-btns {
  margin-top: 32px;
}

.header-btns .btn {
  margin: 6px;
}

.food-icon {
  margin-left: 4px;
}

@media screen and (max-width: 960px) {
  .header-container > h1 {
    font-size: 70px;
    margin-top: -150px;
  }
}

@media screen and (max-width: 768px) {
  .header-container > h1 {
    font-size: 50px;
    margin-top: -100px;
  }

  .header-container > p {
    font-size: 30px;
  }

  .btn-mobile {
    display: block;
    text-decoration: none;
  }

  .btn {
    width: 100%;
  }
}
