.rooms__container {
  background-color: #fff;
  width: 90vw;
  margin: 20px auto;
  max-width: 1000px;
}

.rooms__title {
  font-size: 2rem;
  text-align: center;
  color: #007f28;
  padding-top: 1.5rem;
  font-weight: bold;
}

.rooms__text {
  text-align: center;
  padding: 1.5rem;
}

.rooms__container img {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  padding-bottom: 1.5rem;
}
