.verhuur__container {
  background-color: #fff;
  width: 90vw;
  margin: 20px auto;
  max-width: 1000px;
  padding-bottom: 0.5rem;
}

.verhuur__title {
  font-size: 2rem;
  text-align: center;
  color: #007f28;
  padding-top: 1.5rem;
  font-weight: bold;
}

.verhuur__text {
  text-align: center;
  padding-top: 1.5rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.verhuur__container img {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  padding-bottom: 1.5rem;
}

.verhuur__item {
  background: #f4f4f4;
  padding: 0.5rem 1rem;
  border-radius: 10px;
  margin: 1rem;
  overflow: hidden;
}

.verhuur__btn {
  float: right;
}

.verhuur__item__name {
  display: inline-block;
  font-size: 1rem;
  font-weight: bold;
}

.verhuur__item__prijs {
  color: #617d98;
  font-size: 0.85rem;
  display: inline-block;
}
